<template>
    <StockistForm :stockist="stockist" v-if="loaded" :readOnly="false" />
</template>

<script>
import {BModal, BButton, VBModal, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'
import StockistForm from './StockistForm.vue'

export default {
  components: {
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton,
    StockistForm
  },
  data() {
    return {
      stockist:null,
      loaded:false,
    }
  },
  methods:{
    
  },
  async mounted()
  {
    this.stockist=await APIRequest.detail('stockists',this.$route.params.id)
    this.loaded=true
  }
}
</script>
